import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Css/ContriesDetails.css";
import Tweet from "../Images/Tweetlogo.png";
import back from "../Images/back_arrow.png";
import Like from "../Images/Likelogo.png";
import Home from "./Home";
import axios from "../Schemas/Api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ContriesDetails = () => {
  const route = useLocation();
  const DetailId = route?.state?.item;
  const [loading, setloading] = useState(true);
  const [MainRespons, setMainRespons] = useState("");
  console.log(MainRespons, "sshh");

  const Country = MainRespons?.country_name;
  console.log(Country, "Country");
  const Navigate = useNavigate();

  const ContriesOf = async () => {
    setloading(true);
    try {
      let poly = {
        url: `/get_geocountry_by_id?id=${DetailId?.id}`,
        method: "Get",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          console.log(respons, "MainResponsMainResponsMainResponsMainRespons");
          setMainRespons(respons.data);
          setloading(false);
        });
    } catch (error) {
      console.log("userap...", error);
      setloading(false);
    }
  };
  useEffect(() => {
    ContriesOf();
  }, []);

  return (
    <>
      <Home />
      <div className="buttn-logo-div">
        <div className="Pading-ka-liya">
          <div className="logo-btndiv">
            {/* <div className="dono-btn-kaliya">
              <button className="like-img-bnt">
                <img className="like-img" src={Like} alt="" />
                Like
              </button>
              <button className="like-img-bnt">
                <img className="like-img" src={Tweet} alt="" />
                Tweet
              </button>
            </div> */}
          </div>
          <div>
            <span className="Google-span-ha-Views"> Detail Of </span>{" "}
            <span className="Sphere-span-ha-Photo">
              {MainRespons?.country_name}
            </span>
          </div>
        </div>
      </div>
      <section className="ContryData-main-sec">
        <div className="back-ka-div-ContryData">
          <div onClick={() => Navigate(-1)} className="back-ka-div">
            <img src={back} className="Back-btn-ok" alt="" />
            <span>Back</span>
          </div>
          <div className="card-mainsec">
            <div className="Country-detail-main-c">
              <span className="Style-or-text">Flag</span>
              <div className="main-img-and-detail">
                <img
                  src={MainRespons?.country_data?.[Country]?.Flag}
                  alt=""
                  className="Main-logo-cuntry"
                />
                <div className="sating-maro">
                  <span className="Style-or-text">
                    Country Name :{" "}
                    <span className="main">{MainRespons?.country_name}</span>
                  </span>
                  <span className="Style-or-text">
                    Land Area :{" "}
                    <span className="main">{MainRespons?.land_area}</span>{" "}
                  </span>
                  <span className="Style-or-text">
                    Population :{" "}
                    <span className="main">{MainRespons?.population}</span>{" "}
                  </span>

                  <span className="Style-or-text">
                    Population Percentage :{" "}
                    <span className="main">
                      {MainRespons?.population_percentage}
                    </span>{" "}
                  </span>
                  <span className="Style-or-text">
                    Area :{" "}
                    <span className="main">
                      {MainRespons?.country_data?.[Country]?.Area}
                    </span>{" "}
                  </span>

                  <span className="Style-or-text">
                    CallingCode :{" "}
                    <span className="main">
                      {MainRespons?.country_data?.[Country]?.CallingCode}
                    </span>{" "}
                  </span>
                  <span className="Style-or-text">
                    Capital :{" "}
                    <span className="main">
                      {MainRespons?.country_data?.[Country]?.Capital}
                    </span>{" "}
                  </span>

                  <div>
                    <span className="Style-or-text-conteeee">
                      {" "}
                      Coat Of Arms :{" "}
                    </span>
                    <span className="ComntryOrrr">
                      {MainRespons?.country_data?.[Country]?.CoatOfArms}
                    </span>
                  </div>

                  <span className="Style-or-text">
                    Common Name :{" "}
                    <span className="main">
                      {MainRespons?.country_data?.[Country]?.CommonName}
                    </span>{" "}
                  </span>
                </div>
              </div>
              <span className="Style-or-text">
                Culture And Society :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.CultureAndSociety}
                </span>{" "}
              </span>
              <span className="Style-or-text">
                Currency :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Currency}
                </span>{" "}
              </span>
              <span className="Style-or-text">
                Demographics :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Demographics}
                </span>{" "}
              </span>
              <span className="Style-or-text">
                Demonym :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Demonym}
                </span>{" "}
              </span>
              <span className="Style-or-text">
                Driving Side :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.DrivingSide}
                </span>{" "}
              </span>
              <span className="Style-or-text">
                Economy :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Economy}
                </span>{" "}
              </span>
              <span className="Style-or-text">
                Etymology :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Etymology}
                </span>{" "}
              </span>
              <div className="mt-5 flag-Description-and-img">
                <img
                  src={MainRespons?.country_data?.[Country]?.Flag}
                  alt=""
                  className="Main-logo-cuntry-Falg"
                />
                <span className="Style-or-text">
                  Flag Description :{" "}
                  <span className="main">
                    {MainRespons?.country_data?.[Country]?.FlagDescription}
                  </span>{" "}
                </span>
              </div>
              <span className="Style-or-text">
                GDP :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.GDP}
                </span>{" "}
              </span>
              <span className="Style-or-text">
                GDP Per Capita :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.GDPPerCapita}
                </span>
              </span>
              <span className="Style-or-text">
                Geography :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Geography}
                </span>
              </span>
              <span className="Style-or-text">
                Government :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Government}
                </span>
              </span>
              <span className="Style-or-text">
                History :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.History}
                </span>
              </span>
              <span className="Style-or-text">
                ISO Code :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.ISOCode}
                </span>
              </span>
              <span className="Style-or-text">
                Infrastructure :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Infrastructure}
                </span>
              </span>
              <span className="Style-or-text">
                Internet TLD :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.InternetTLD}
                </span>
              </span>
              <span className="Style-or-text">
                Largest City :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.LargestCity}
                </span>
              </span>
              <span className="Style-or-text">
                Legislature :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Legislature}
                </span>
              </span>

              <span className="Style-or-text">
                Military :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Military}
                </span>
              </span>

              {MainRespons?.country_data?.[Country]?.OfficialLanguages.map(
                (itm, index) => {
                  return (
                    <span className="Style-or-text">
                      Official Languages : <span className="main">{itm}</span>
                    </span>
                  );
                }
              )}
              <span className="Style-or-text">
                Official Name :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.OfficialName}
                </span>
              </span>
              <span className="Style-or-text">
                Politics :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Politics}
                </span>
              </span>
              <span className="Style-or-text">
                Population :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Population}
                </span>
              </span>
              <span className="Style-or-text">
                Premier :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Premier}
                </span>
              </span>

              <span className="Style-or-text">
                President :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.President}
                </span>
              </span>
              <span className="Style-or-text">
                Science And Technology :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.ScienceAndTechnology}
                </span>
              </span>
              <span className="Style-or-text">
                Time zone :{" "}
                <span className="main">
                  {MainRespons?.country_data?.[Country]?.Timezone}
                </span>
              </span>
            </div>
          </div>
        </div>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default ContriesDetails;
